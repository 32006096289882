import React from 'react';

import './podcast_list_view.scss';

function PodcastListView() {
  return (
    <div className="container-fluid">
      <div className="row center-md">
        <div className="col-md-6">NO</div>
      </div>
    </div>
  );
}

export default PodcastListView;
