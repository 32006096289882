import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Howl } from 'howler';

import { setIsPlaying } from '../../../actions';
import './audio_player.scss';

function AudioPlayer() {
  let [sound, setSound] = useState(null);
  const dispatch = useDispatch();
  const src = useSelector(({ lessons }) => lessons.currentSentence.audio);

  useEffect(() => {
    let clip = new Howl({
      src: ['data:audio/mp3;base64,' + src]
    });

    if (sound !== null) {
      setTimeout(() => {
        clip.play();
      }, 300);
    } else {
      clip.play();
    }
    setSound(clip);

    return () => {
      clip.fade(1, 0, 300);
    };
  }, [src]);

  return (
    <div
      onClick={() => {
        sound.stop();
        sound.play();

        dispatch(setIsPlaying({ isPlayingSound: true }));
      }}
      className="audio-player"
    >
      <button className="audio-player__button"></button>
    </div>
  );
}

export default AudioPlayer;
