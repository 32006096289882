import React from 'react';

import LessonCell from '../LessonCell/LessonCell';
import './lessons.scss';

function Lessons({ lessons, onClickLesson }) {
  return (
    <div className="lessons">
      {lessons.map(lesson => {
        return (
          <LessonCell key={lesson.id} onClick={onClickLesson} lesson={lesson} />
        );
      })}
    </div>
  );
}

export default Lessons;
