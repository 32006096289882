import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useParams, useHistory } from 'react-router-dom';
import PropagateLoader from 'react-spinners/PropagateLoader';

import delayAtLeast from '../../common/delayAtLeast';
import { startLesson, goToNext } from '../../actions';
import ProgressBar from '../../common/ProgressBar/';
import Controls from './Controls/Controls';
import Powerups from './Powerups/Powerups';
import AnswerInput from './AnswerInput/AnswerInput';

import './lesson.scss';

function Lesson() {
  let dispatch = useDispatch();
  let { lessonId } = useParams();
  let history = useHistory();
  let [isLoading, setLoading] = useState(true);
  let [lessonVisible, setLessonVisible] = useState(false);

  let isSetOver = useSelector(({ lessons }) => lessons.isSetOver);
  let isCorrect = useSelector(({ lessons }) => lessons.isCorrect);
  let currentCompletedPct = useSelector(
    ({ lessons }) => lessons.currentCompletedPct
  );

  function addKeyboardShortcuts(event) {
    if (isCorrect && event.which == 13) {
      dispatch(goToNext());
    }
  }

  useEffect(() => {
    if (!isSetOver) {
      delayAtLeast(700, dispatch(startLesson(lessonId))).then(() => {
        setLoading(false);
        setTimeout(() => {
          setLessonVisible(true);
        }, 100);
      });
    } else {
      setTimeout(() => {
        setLessonVisible(false);
        setTimeout(() => {
          history.replace('/app/results');
        }, 300);
      }, 300);
    }
  }, [isSetOver]);

  useEffect(() => {
    window.addEventListener('keydown', addKeyboardShortcuts);
    return () => {
      window.removeEventListener('keydown', addKeyboardShortcuts);
    };
  }, [isCorrect]);

  return (
    <div className="container-fluid">
      <CSSTransition
        in={lessonVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="row lesson">
          <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3">
            <div style={{ position: 'relative' }}>
              <ProgressBar
                className="lesson__progress"
                progress={currentCompletedPct}
              />
            </div>
            <Powerups className="lesson__powerups" />
            <AnswerInput className="lesson__answer-input" />
            <Controls className="lesson__controls" />
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames="spinner"
        unmountOnExit
      >
        <div className="spinner">
          <PropagateLoader color={'#187dff'} />
        </div>
      </CSSTransition>
    </div>
  );
}

export default Lesson;
