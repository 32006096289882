import { createAction } from '@reduxjs/toolkit';
import uuid from 'uuid/v4';
import get from 'lodash/get';

import api from './apiClient';
import playSound from './common/playSound';

const updateLessons = createAction('lessons/update');
const updateUser = createAction('user/update');
const updateCurrentLesson = createAction('lessons/updateCurrentLesson');
const enableRevealOne = createAction('lessons/enableRevealOne');
const disableRevealOne = createAction('lessons/disableRevealOne');
const updateSingleWord = createAction('lessons/updateSingleWord');
const updateAnswer = createAction('lessons/updateAnswer');
const goToNextSentence = createAction('lessons/goToNextSentence');
const updateSetResults = createAction('lessons/updateSetResults');
const resetLesson = createAction('lessons/resetLesson');
const overrideCompletedPct = createAction('lessons/overrideCompletedPct');
const updateMany = createAction('lessons/updateMany');
const updateFirstLetters = createAction('lessons/updateFirstLetters');
const updateAnswerStatus = createAction('lessons/updateAnswerStatus');
const setIsChecking = createAction('lessons/setIsChecking');
const setIsPlaying = createAction('lesson/setIsPlaying');
const unlockLesson = createAction('lesson/unlockLesson');

const requestUnlockLesson = lessonId => {
  return (dispatch, getState) => {
    return api.post('/unlock_lesson', { lesson_id: lessonId }).then(res => {
      if (res.data.success) {
        dispatch(unlockLesson({ lessonId }));
      }
    });
  };
};
const checkAnswer = () => {
  return (dispatch, getState) => {
    const id = get(getState(), 'lessons.currentSentence.id');
    const answer = get(getState(), 'lessons.currentAnswer');
    dispatch(disableRevealOne());

    return api.post('/check_answer', { id, answer }).then(res => {
      playSound('check');
      dispatch(
        updateAnswerStatus({
          isCorrect: res.data.is_correct,
          mask: res.data.mask,
          fixed: res.data.fixed
        })
      );
    });
  };
};

const goToNext = () => {
  return (dispatch, getState) => {
    const count = get(getState(), 'lessons.currentLesson.sentences.length');
    const currentIndex = get(getState(), 'lessons.currentIndex');

    if (currentIndex < count - 1) {
      const id = get(getState(), 'lessons.currentSentence.id');
      if (!id) return;
      dispatch(goToNextSentence());
    } else {
      setTimeout(() => {
        playSound('finish');
      }, 300);

      dispatch(overrideCompletedPct({ pct: 100 }));
      return dispatch(completeSet());
    }
  };
};

const skipSentence = () => {
  return (dispatch, getState) => {
    const count = get(getState(), 'lessons.currentLesson.sentences.length');
    const currentIndex = get(getState(), 'lessons.currentIndex');

    if (currentIndex < count - 1) {
      playSound('skip');
      const id = get(getState(), 'lessons.currentSentence.id');
      if (!id) return;

      return api.post('/skip', { id }).then(() => {
        dispatch(goToNextSentence());
      });
    } else {
      setTimeout(() => {
        playSound('finish');
      }, 300);

      dispatch(overrideCompletedPct({ pct: 100 }));
      return dispatch(completeSet());
    }
  };
};

const completeSet = () => {
  return (dispatch, getState) => {
    const setId = get(getState(), 'lessons.currentLesson.set_id');
    if (!setId) return;

    return api.post('/complete_set', { id: setId }).then(({ data }) => {
      dispatch(updateSetResults(data));
    });
  };
};

const revealField = index => {
  return (dispatch, getState) => {
    const sentenceId = get(getState(), 'lessons.currentSentence.id');
    return api
      .post('/powerups_reveal_one', { id: sentenceId, index })
      .then(res => {
        playSound('revealOne');
        dispatch(
          updateSingleWord({
            index,
            word: res.data.word,
            revealOne: res.data.powerups_reveal_one
          })
        );
      });
  };
};

const revealMany = () => {
  return (dispatch, getState) => {
    const sentenceId = get(getState(), 'lessons.currentSentence.id');
    const answer = get(getState(), 'lessons.currentAnswer');

    return api
      .post('/powerups_reveal_many', { id: sentenceId, answer })
      .then(res => {
        playSound('revealMany');
        dispatch(
          updateMany({
            updatedIndices: res.data.updated_indices,
            words: res.data.words,
            revealMany: res.data.powerups_reveal_many
          })
        );
      });
  };
};

const revealFirst = () => {
  return (dispatch, getState) => {
    const sentenceId = get(getState(), 'lessons.currentSentence.id');
    const answer = get(getState(), 'lessons.currentAnswer');

    return api
      .post('/powerups_reveal_first', { id: sentenceId, answer })
      .then(res => {
        playSound('revealMany');
        dispatch(
          updateFirstLetters({
            words: res.data.words,
            revealFirst: res.data.powerups_reveal_first
          })
        );
      });
  };
};

const startLesson = lessonId => {
  return (dispatch, getState) => {
    return api.post('/start_lesson', { id: lessonId }).then(res => {
      dispatch(updateCurrentLesson({ ...res.data }));
    });
  };
};

const getLessons = () => {
  return (dispatch, getState) => {
    return api.get('/lessons').then(res => {
      dispatch(
        updateLessons({
          lessons: res.data.lessons
        })
      );
    });
  };
};

function createAccount(name) {
  return (dispatch, getState) => {
    return api
      .post('/create_account', {
        id: uuid(),
        nickname: name
      })
      .then(res => {
        dispatch(updateUser({ user: res.data }));
        return res;
      });
  };
}

function updateNickname(nickname) {
  return (dispatch, getState) => {
    return api
      .post('/update_nickname', {
        nickname
      })
      .then(res => {
        dispatch(updateUser({ user: res.data }));
        return res;
      });
  };
}

function getUserInfo() {
  return (dispatch, getState) => {
    return api.get('/user').then(res => {
      dispatch(updateUser({ user: res.data }));
      return res;
    });
  };
}

export {
  updateLessons,
  getLessons,
  createAccount,
  updateUser,
  getUserInfo,
  startLesson,
  updateCurrentLesson,
  enableRevealOne,
  disableRevealOne,
  revealField,
  revealMany,
  revealFirst,
  updateSingleWord,
  updateAnswer,
  skipSentence,
  goToNext,
  goToNextSentence,
  completeSet,
  updateSetResults,
  checkAnswer,
  resetLesson,
  overrideCompletedPct,
  updateMany,
  updateFirstLetters,
  updateAnswerStatus,
  setIsChecking,
  setIsPlaying,
  updateNickname,
  unlockLesson
};
