import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import localStore from 'store';
import { Provider, useStore, useDispatch } from 'react-redux';
import store from './store';
import LandingPage from './pages/LandingPage/';
import CreateUser from './pages/CreateUser/';
import UpdateNickname from './pages/UpdateNickname/';
import ListView from './pages/ListView';
import Lesson from './pages/Lesson';
import Results from './pages/Results';
import Leaderboard from './pages/Leaderboard';
import { ListView as PodcastListView } from './pages/Podcast';

import { getUserInfo } from './actions';

import './common.scss';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (localStore.get('token') && !store.getState().user.isLoggedIn) {
      dispatch(getUserInfo()).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  if (!localStore.get('token')) {
    return <Redirect to="/app/create" />;
  }

  if (store.getState().user.isLoggedIn) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return <div />;
  }
};

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route path="/app/create">
              <CreateUser />
            </Route>
            <Route path="/app/update-nickname/:userId">
              <UpdateNickname />
            </Route>
            <PrivateRoute path="/app/lesson/:lessonId">
              <Lesson />
            </PrivateRoute>
            <PrivateRoute path="/app/lessons">
              <ListView />
            </PrivateRoute>
            <PrivateRoute path="/app/results">
              <Results />
            </PrivateRoute>
            <PrivateRoute path="/app/leaderboard">
              <Leaderboard />
            </PrivateRoute>
            <Route path="/podcast">
              <PodcastListView />
            </Route>
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
