import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import LeaderboardListItem from './LeaderboardListItem';
import delayAtLeast from '../../common/delayAtLeast';
import api from '../../apiClient';
import PropagateLoader from 'react-spinners/PropagateLoader';
import './leaderboard.scss';

function Leaderboard() {
  let [isLoading, setLoading] = useState(true);
  let [listVisible, setListVisible] = useState(false);
  let [listItems, setListItems] = useState([]);
  let [userRank, setUserRank] = useState({});

  useEffect(() => {
    delayAtLeast(800, api.get('/leaderboard?range=week')).then(res => {
      setListItems(res.data.users);
      setUserRank({
        points: res.data.points,
        nickname: res.data.nickname,
        rank: res.data.rank
      });
      setLoading(false);
      setListVisible(true);
    });
  }, []);
  return (
    <div className="row center-xs center-md leaderboard">
      <CSSTransition
        in={listVisible}
        timeout={1000}
        classNames="fade"
        unmountOnExit
      >
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="leaderboard__bg" />
          <div className="leaderboard__list">
            <div className="leaderboard__list-header">Points this week</div>
            <div className="leaderboard__user-rank">
              <LeaderboardListItem
                isUser={true}
                nickname={userRank.nickname}
                points={userRank.points}
                rank={userRank.rank}
              />
            </div>

            {listItems.slice(0, 5).map(user => {
              return (
                <LeaderboardListItem
                  nickname={user.nickname}
                  points={user.points}
                  rank={user.rank}
                />
              );
            })}
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames="spinner"
        unmountOnExit
      >
        <div className="spinner">
          <PropagateLoader color={'#187dff'} />
        </div>
      </CSSTransition>
    </div>
  );
}

export default Leaderboard;
