import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import store from 'store';
import { GoogleLoginButton } from 'react-social-login-buttons';

import close from '../Lesson/close.png';

import bg from './bg.svg';
import bg2 from './bg2.svg';

import character from './character.svg';
import dictizeLogo from './dictize.png';

import './landing_page.scss';

function LandingPage() {
  let [modalIsOpen, setModalOpen] = useState(false);
  let history = useHistory();

  return (
    <React.Fragment>
      <div className="landing-page__wrapper">
        <img className="landing-page__bg1" src={bg} />
        <div className="container-fluid">
          <nav className="row">
            <div className="landing-page__logo col-md-1 col-xs-12 col-lg-1 col-sm-1">
              <img className="landing-page__logo-img" src={dictizeLogo} />
              Dictize
            </div>
            <ul className="landing-page__links col-xs-8 col-xs-offset-2 col-lg-4 col-lg-offset-7 col-md-offset-6 col-md-5 col-sm-6 col-sm-offset-5">
     {/*         <li>
                <Link to="/podcast">Podcast</Link>
              </li>*/}
              {store.get('token') && (
                <li
                  onClick={() => {
                    store.set('token', null);
                    window.location.reload();
                  }}
                >
                  Log Out
                </li>
              )}
            </ul>
          </nav>
          <section className="row landing-page__hero center-xs start-md">
            <div className="col-md-5 col-lg-4 info col-xs-10">
              <h1 className="landing-page__title">
                Improve your
                <br />
                listening comprehension
              </h1>
              <h3 className="landing-page__subtitle">
                Learn English by listening to native speakers  using common
                phrases
              </h3>
              <div className="">
                {store.get('token') ? (
                  <Link to="/app/lessons">
                    <button className="btn guest-button">Play Now</button>
                  </Link>
                ) : (
                  <span>
                    <Link to="/app/create">
                      <button className="btn guest-button">
                        Play as Guest
                      </button>
                    </Link>
                    <button
                      className="btn signup-button"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      Sign Up
                    </button>
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-5 col-lg-6 col-xs-10">
              <img
                className="landing-page__character center-xs"
                src={character}
              />
            </div>
          </section>
        </div>
      </div>

      <img className="landing-page__bg2" src={bg2} />
      <div className="footer">
        <div className="container">
          <div className="row center-xs">
            © 2020 Dictize
            <a href="https://icons8.com">Illustration by Ouch.pics</a>
          </div>
        </div>
      </div>
      <Modal
        className="signup"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <img
          className="signup__close"
          onClick={() => setModalOpen(false)}
          src={close}
        />
        <h2>Sign Up</h2>
        <p>Create an account so you can save your progress</p>
        <GoogleLoginButton
          className="signup__btn"
          align="center"
          onClick={() => {
            window.location.href = 'https://localhost:8000/login';
          }}
        >
          <span>Sign up with Google</span>
        </GoogleLoginButton>
        <div className="signup__divider">
          <div className="signup__divider-text">or</div>
        </div>
        <div className="signup__guest-button">
          <Link to="/app/create">Play as Guest</Link>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default LandingPage;
