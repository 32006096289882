export default async function delayAtLeast(ms, fn) {
  let start = new Date();

  return new Promise(async resolve => {
    if (fn) {
      await fn.then(res => {
        let end = new Date();
        let diff = end - start;
        if (diff >= ms) {
          resolve(res);
        } else {
          setTimeout(() => {
            resolve(res);
          }, ms - diff);
        }
      });
    } else {
      setTimeout(() => resolve(), ms);
    }
  });
}
