import React, { useEffect, useState } from 'react';

import apiClient from '../../../apiClient';
import Row from './Row';

import './compact_leaderboard.scss';

function CompactLeaderboard({ className = '' }) {
  const [results, setResults] = useState({ index: null, users: [] });

  useEffect(() => {
    apiClient.get('/user_leaderboard').then(res => {
      setResults(res.data);
    });
  }, []);

  return (
    <div className={className}>
      <div className="compact-leaderboard">
        {results.users.map((user, index) => {
          return (
            <Row
              isUser={index === results.index}
              nickname={user.nickname}
              points={user.points}
              rank={user.rank}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CompactLeaderboard;
