import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Powerup from '../Powerup/Powerup';

import {
  enableRevealOne,
  disableRevealOne,
  revealFirst,
  revealMany
} from '../../../actions';

import './powerups.scss';

function Powerups({ className }) {
  const dispatch = useDispatch();
  const isUsingRevealOne = useSelector(
    ({ lessons }) => lessons.isUsingRevealOne
  );
  const powerups = useSelector(({ user }) => user.powerups);

  function handleClick(powerupType) {
    if (powerups[powerupType] <= 0) return;

    if (powerupType === 'revealOne') {
      if (isUsingRevealOne) {
        dispatch(disableRevealOne());
      } else {
        dispatch(enableRevealOne());
      }

      return;
    } else if (powerupType === 'revealMany') {
      dispatch(revealMany());
    } else if (powerupType === 'revealFirst') {
      dispatch(revealFirst());
    }
  }

  return (
    <div className={className}>
      <div className="powerups">
        <Powerup
          onClick={handleClick}
          type="revealOne"
          count={powerups.revealOne}
        />
        <Powerup
          onClick={handleClick}
          type="revealMany"
          count={powerups.revealMany}
        />
        <Powerup
          onClick={handleClick}
          type="revealFirst"
          count={powerups.revealFirst}
        />
      </div>
    </div>
  );
}

export default Powerups;
