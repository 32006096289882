import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import PropagateLoader from 'react-spinners/PropagateLoader';
import delay from 'lodash/delay';

import delayAtLeast from '../../common/delayAtLeast';
import Lessons from './Lessons/Lessons';
import Header from './Header/Header';

import {
  getLessons,
  getUserInfo,
  resetLesson,
  unlockLesson
} from '../../actions';

import './list_view.scss';

function ListView() {
  let dispatch = useDispatch();
  let history = useHistory();
  let [modalIsOpen, setModalOpen] = useState(false);

  let lessons = useSelector(({ lessons }) => lessons.lessons);
  let isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  let [isLoading, setLoading] = useState(true);
  let [listVisible, setListVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetLesson());
    dispatch(getUserInfo());
    if (!lessons.length) {
      delayAtLeast(800, dispatch(getLessons())).then(() => {
        setLoading(false);
        setListVisible(true);
      });
    } else {
      delayAtLeast(800).then(() => {
        setLoading(false);
        setListVisible(true);
      });
    }
  }, []);

  function handleClickLesson(lesson) {
    if (lesson.unlocked) {
      setListVisible(false);
      delay(history.push, 300, `/app/lesson/${lesson.id}`);
    } else {
      setModalOpen(true);
    }
  }

  return (
    <div className="row list-view">
      <CSSTransition
        in={listVisible}
        timeout={1000}
        classNames="fade"
        unmountOnExit
      >
        <div className="center-xs col-xs-12 col-md-10 col-md-offset-1">
          <Header
            onRequestRouteChange={fn => {
              setListVisible(false);
              delay(fn, 300);
            }}
          />
          <Lessons lessons={lessons} onClickLesson={handleClickLesson} />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames="spinner"
        unmountOnExit
      >
        <div className="spinner">
          <PropagateLoader color={'#187dff'} />
        </div>
      </CSSTransition>
    </div>
  );
}

export default ListView;
