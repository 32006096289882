import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import coin from './coin.png';
import './header.scss';

function Header({ onRequestRouteChange }) {
  let history = useHistory();
  let nickname = useSelector(({ user }) => user.profile.nickname);
  let points = useSelector(({ user }) => user.profile.points);
  let coins = useSelector(({ user }) => user.profile.coins);

  return (
    <div className="row center-xs center-md">
      <div className="header col-xs-10 col-md-10">
        <div className="header__user-info">
          <div className="header__nickname">{nickname}</div>
          <div className="header__points">{points}</div>
        </div>
        <div
          onClick={() => {
            onRequestRouteChange(() => {
              history.push('/app/leaderboard');
            });
          }}
          className="header__leaderboard-icon"
        />
        <div className="header__coins">
          <img src={coin} className="header__coins-img" />
          <div className="header__coins-count">{coins}</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
