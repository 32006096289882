import React from 'react';
import { useDispatch } from 'react-redux';

import { skipSentence } from '../../../actions';
import './skip_button.scss';

function SkipButton() {
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(skipSentence())} className="skip-button">
      SKIP
    </button>
  );
}

export default SkipButton;
