import React from 'react';
import cx from 'classnames';

function Row({ isUser, rank, nickname, points }) {
  return (
    <div
      className={cx('compact-leaderboard__row', {
        'compact-leaderboard__row--highlighted': isUser
      })}
    >
      <div className="compact-leaderboard__rank">{rank}</div>
      <div className="compact-leaderboard__nickname">{nickname} </div>
      <div className="compact-leaderboard__points">{points}</div>
    </div>
  );
}

export default Row;
