import React from 'react';
import cx from 'classnames';

import './progress_bar.scss';

function ProgressBar({ progress, className }) {
  return (
    <div className={cx('progress', className)}>
      <div className="progress__bar" style={{ width: progress + '%' }}></div>
    </div>
  );
}

export default ProgressBar;
