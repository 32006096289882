import React from 'react';
import cx from 'classnames';

import ProgressBar from '../../../common/ProgressBar/';
import lock from './lock.svg';
import completed from './completed.svg';

import './lesson_cell.scss';

function LessonCell({ lesson, onClick }) {
  const isCompleted = lesson.completed === lesson.total;
  return (
    <div
      onClick={() => onClick(lesson)}
      className={cx('lesson-cell', {
        'lesson-cell--locked': !lesson.unlocked,
        'lesson-cell--completed': isCompleted
      })}
    >
      <div style={{ position: 'relative' }}>
        <img
          className="lesson-cell__img"
          src={`data:image/png;base64, ${lesson.icon}`}
        />
        {!lesson.unlocked && (
          <div className="lesson-cell__lock-wrap">
            <img className="lesson-cell__lock" src={lock} />
          </div>
        )}
        {isCompleted && (
          <div className="lesson-cell__completed-wrap">
            <img className="lesson-cell__completed" src={completed} />
          </div>
        )}
      </div>
      <div className="lesson-cell__title">{lesson.title}</div>
      <ProgressBar
        className="lesson-cell__progress"
        progress={lesson.percent * 100}
      />
    </div>
  );
}

export default LessonCell;
