import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import PropagateLoader from 'react-spinners/PropagateLoader';
import store from 'store';
import delay from 'lodash/delay';

import { updateNickname } from '../../actions';

import NicknameForm from '../../common/NicknameForm/';
import api from '../../apiClient';

import './update_nickname.scss';

function UpdateNickname() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [showNickname, setShowNickname] = useState(false);
  const [nicknameError, setNicknameError] = useState(false);

  useEffect(() => {
    // Set api token
    store.set('token', userId);
    api.defaults.headers.common['Authorization'] = userId;

    delay(setShowNickname, 1300, true);
    delay(setLoading, 1000, false);
  }, []);

  return (
    <div className="update-nickname container-fluid">
      <CSSTransition
        in={showNickname}
        timeout={1000}
        classNames="fade"
        unmountOnExit
      >
        <NicknameForm
          title={'Update Nickname'}
          saveButtonText={'Update'}
          onSubmit={nickname => {
            setNicknameError('');
            dispatch(updateNickname(nickname)).then(res => {
              if (res.data.success) {
                history.replace('/app/lessons');
              } else {
                setNicknameError(res.data.message);
              }
            });
          }}
          errorMessage={nicknameError}
          setErrorMessage={setNicknameError}
        />
      </CSSTransition>
      <CSSTransition
        in={isLoading}
        timeout={1000}
        classNames="spinner"
        unmountOnExit
      >
        <div className="spinner">
          <PropagateLoader color={'#187dff'} />
        </div>
      </CSSTransition>
    </div>
  );
}

export default UpdateNickname;
