import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import PropagateLoader from 'react-spinners/PropagateLoader';
import store from 'store';
import delay from 'lodash/delay';

import { createAccount, getUserInfo } from '../../actions';

import NicknameForm from '../../common/NicknameForm/';
import api from '../../apiClient';

import './create_user.scss';

function CreateUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [showNickname, setShowNickname] = useState(false);
  const [nicknameError, setNicknameError] = useState(false);

  useEffect(() => {
    if (!store.get('token')) {
      delay(setShowNickname, 1300, true);
      delay(setLoading, 1000, false);
      return;
    } else {
      dispatch(getUserInfo());
      history.replace('/app/lessons');
    }
  }, []);

  return (
    <div className="create-user container-fluid">
      <CSSTransition
        in={showNickname}
        timeout={1000}
        classNames="fade"
        unmountOnExit
      >
        <NicknameForm
          onSubmit={name => {
            setNicknameError('');
            dispatch(createAccount(name))
              .then(res => {
                if (res.status === 200) {
                  setNicknameError('');
                  setShowNickname(false);
                  setLoading(true);
                  store.set('token', res.data.id);
                  api.defaults.headers.common['Authorization'] = res.data.id;
                  history.replace('/app/lessons');
                }
              })
              .catch(res => {
                debugger;
                if (res.response.status === 422) {
                  setNicknameError(res.response.data.message);
                }
              });
          }}
          errorMessage={nicknameError}
          setErrorMessage={setNicknameError}
        />
      </CSSTransition>
      <CSSTransition
        in={isLoading}
        timeout={1000}
        classNames="spinner"
        unmountOnExit
      >
        <div className="spinner">
          <PropagateLoader color={'#187dff'} />
        </div>
      </CSSTransition>
    </div>
  );
}

export default CreateUser;
