import React, { useState, useEffect, useRef } from 'react';
import { connect, useStore, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import cx from 'classnames';

import {
  revealField,
  disableRevealOne,
  updateAnswer,
  setIsChecking,
  setIsPlaying,
  checkAnswer
} from '../../../actions';

import './answer_input.scss';

const BACKSPACE = 8;
const ENTER = 13;

class Input extends React.Component {
  state = {
    value: '',
    specialChar: null,
    isIncorrect: false,
    isCompleted: false,
    isCorrected: false
  };

  constructor(props) {
    super(props);
    this.hiddenEl = React.createRef();
    this.inputEl = React.createRef();
    let re = /!|\?|\.|,/g;
    let match = props.field.match(re);
    if (match) {
      this.state.specialChar = match[0];
    }
  }

  componentDidMount() {
    const { width } = this.hiddenEl.current.getBoundingClientRect();
    this.hiddenEl.current.style.display = 'none';
    const padded = width + 24;
    this.inputEl.current.style.width = `${padded}px`;
  }

  componentDidUpdate() {
    // Keep these values in sync to avoid mess in layer above
    if (this.props.prefilled && this.state.value !== this.props.prefilled) {
      this.setState({
        value: this.props.prefilled
      });
    }
  }

  setValue = value => {
    this.setState({ value });
  };

  handleChange = e => {
    const { specialChar } = this.state;
    const { field, onEnd, index, onChange } = this.props;
    let text = e.target.value;
    let length = specialChar ? field.length - 1 : field.length;

    if (text.length <= length) {
      if (text.length === length) {
        this.setValue(text);
        onEnd(index);
      }
      this.setValue(text);

      if (specialChar) {
        text += specialChar;
      }

      onChange(index, text);
    }
  };

  handleKeyDown = e => {
    const { onBack, index, onEnter } = this.props;
    if (e.which === ENTER) {
      onEnter();
      e.preventDefault();
      e.stopPropagation();
    } else if (e.which === BACKSPACE && this.state.value === '') {
      onBack(index);
    }
  };

  markCorrect = () => {
    this.setState({ isIncorrect: false });
  };

  markCorrected = val => {
    this.setValue(val);
    this.setState({
      isCorrected: true
    });
  };

  markIncorrect = () => {
    this.setState({ isIncorrect: true });
  };

  markCompleted = () => {
    this.setState({ isIncorrect: false, isCompleted: true });
  };

  reset = () => {
    this.setState({
      isCompleted: false,
      isIncorrect: false,
      isCorrected: false
    });
  };

  render() {
    const {
      field,
      className = '',
      style,
      disabled,
      onClick,
      index,
      prefilled,
      onFocus
    } = this.props;

    return (
      <div
        onClick={() => onClick(index)}
        style={style}
        className={cx('answer-input__wrapper', className, {
          'answer-input--incorrect': this.state.isIncorrect,
          'answer-input--completed': this.state.isCompleted,
          'answer-input--corrected': this.state.isCorrected
        })}
      >
        <span className="answer-input__hidden" ref={this.hiddenEl}>
          {field}
        </span>
        {prefilled ? (
          <input
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            ref={this.inputEl}
            className="answer-input__field"
            value={prefilled}
            disabled={true}
          />
        ) : (
          <input
            type="text"
            onFocus={() => {
              onFocus(index);
            }}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            ref={this.inputEl}
            className="answer-input__field"
            value={this.state.value}
            disabled={disabled || this.state.isCompleted}
            autoComplete="true"
          />
        )}
        {!prefilled && (
          <div className="answer-input__special-char">
            {this.state.specialChar}
          </div>
        )}
      </div>
    );
  }
}

function AnswerInput({
  className,
  fields,
  isUsingRevealOne,
  prefilled,
  sentenceId,
  revealFirst,
  isChecking,
  isCorrect,
  isPlayingSound,
  fixed
}) {
  let dispatch = useDispatch();
  let store = useStore();
  let refs = useRef({});
  let [currentId] = useState(sentenceId);
  let [isLoading, setIsLoading] = useState(false);
  let [currentFields, setFields] = useState(fields);
  let [revealFirstCount, setRevealFirst] = useState(revealFirst);
  let [currentIndex, setIndex] = useState(0);

  useEffect(() => {
    if (currentId !== sentenceId) {
      setIsLoading(true);

      setTimeout(() => {
        setTimeout(() => {
          setFields(fields);
          setIsLoading(false);
          focusFirst();
        }, 100);
      }, 300);
    }
  }, [sentenceId]);

  useEffect(() => {
    focusFirst();
  }, [prefilled]);

  useEffect(() => {
    if (revealFirst !== revealFirstCount) {
      setRevealFirst(revealFirst);
      let answer = store.getState().lessons.currentAnswer;
      for (let i = 0; i < answer.length; i++) {
        refs.current[i].setValue(answer[i]);
      }
      focusFirst();
    }
  }, [revealFirst]);

  useEffect(() => {
    if (isCorrect) {
      for (let key in refs.current) {
        if (refs.current[key]) {
          refs.current[key].markCompleted();
        } else {
          delete refs.current[key];
        }
      }
      for (let fixedObj of fixed) {
        refs.current[fixedObj.index].markCorrected(fixedObj.word);
      }
    } else if (isChecking) {
      let answer = store.getState().lessons.currentAnswer;
      let mask = store.getState().lessons.mask;

      for (let i = 0; i < answer.length; i++) {
        if (answer[i].toLowerCase() === mask[i].toLowerCase()) {
          refs.current[i].markCorrect();
        } else {
          refs.current[i].markIncorrect();
        }
      }
      dispatch(setIsChecking({ isChecking: false }));
      setTimeout(() => {
        focusFirstIncorrect();
      }, 0);
    }
  }, [isChecking, isCorrect]);

  useEffect(() => {
    if (isPlayingSound) {
      refs.current[currentIndex].inputEl.current.focus();
      dispatch(setIsPlaying({ isPlayingSound: false }));
    }
  }, [isPlayingSound]);

  function handleEnd(index) {
    let maxLength = currentFields.split(' ').length - 1;
    let answer = store.getState().lessons.currentAnswer;
    let mask = store.getState().lessons.currentSentence.mask.split(' ');

    if (index < maxLength) {
      while (index < maxLength) {
        index++;
        if (
          refs.current[index] &&
          !refs.current[index].inputEl.current.disabled &&
          answer[index].length < mask[index].length
        ) {
          break;
        }
      }
      if (index <= maxLength && refs.current[index]) {
        refs.current[index].inputEl.current.focus();
        setIndex(index);
      }
    }
  }

  function handleBack(index) {
    if (index > 0) {
      while (index > 0) {
        index--;
        if (!refs.current[index].inputEl.current.disabled) {
          break;
        }
      }
      if (index >= 0 && refs.current[index]) {
        refs.current[index].inputEl.current.focus();
        setIndex(index);
      }
    }
  }

  function handleFieldClick(index) {
    if (isUsingRevealOne) {
      dispatch(revealField(index)).then(() => {
        dispatch(disableRevealOne());
        focusFirst();
      });
    }
  }

  function focusFirst() {
    handleEnd(-1);
  }

  function focusFirstIncorrect() {
    let index = -1;
    let maxLength = currentFields.split(' ').length - 1;
    if (index < maxLength) {
      while (index < maxLength) {
        index++;
        if (
          !refs.current[index].inputEl.current.disabled &&
          refs.current[index].state.isIncorrect
        ) {
          break;
        }
      }
      if (index <= maxLength) {
        refs.current[index].inputEl.current.focus();
        setIndex(index);
      }
    }
  }

  function handleChange(index, answer) {
    dispatch(updateAnswer({ index, answer }));
  }

  function onFocus(index) {
    setIndex(index);
  }

  return (
    <CSSTransition
      in={!isLoading}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <div className={className}>
        <div className="answer-input">
          {currentFields.split(' ').map((field, index) => {
            let delay = null;
            if (isUsingRevealOne) {
              delay = { animationDelay: Math.random() * 0.1 + 's' };
            }
            return (
              <Input
                ref={ref => {
                  refs.current[index] = ref;
                }}
                onChange={handleChange}
                onClick={handleFieldClick}
                className={cx({ shake: isUsingRevealOne && !prefilled[index] })}
                style={delay}
                onFocus={onFocus}
                onEnd={handleEnd}
                onBack={handleBack}
                onEnter={() => {
                  dispatch(checkAnswer());
                }}
                key={index}
                index={index}
                field={field}
                disabled={isUsingRevealOne}
                prefilled={prefilled[index]}
              />
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
}

export default connect(({ lessons, user }) => {
  return {
    sentenceId: lessons.currentSentence.id,
    fields: lessons.currentSentence.mask,
    prefilled: lessons.currentSentence.prefilled,
    isUsingRevealOne: lessons.isUsingRevealOne,
    revealFirst: user.powerups.revealFirst,
    isChecking: lessons.isChecking,
    isCorrect: lessons.isCorrect,
    fixed: lessons.fixed,
    isPlayingSound: lessons.isPlayingSound
  };
})(AnswerInput);
