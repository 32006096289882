import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { lessonsReducer, userReducer } from './reducers';
import thunkMiddleware from 'redux-thunk';

const store = configureStore({
  reducer: combineReducers({ lessons: lessonsReducer, user: userReducer }),
  middleware: [thunkMiddleware]
});

export default store;
