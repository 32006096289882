import React from 'react';

import AudioPlayer from '../AudioPlayer/AudioPlayer';
import SkipButton from '../SkipButton/SkipButton';
import CheckButton from '../CheckButton/CheckButton';

import './controls.scss';

function Controls({ className }) {
  return (
    <div className={` ${className}`}>
      <div className="controls">
        <SkipButton />
        <AudioPlayer />
        <CheckButton />
      </div>
    </div>
  );
}

export default Controls;
