import React, { useRef, useEffect } from 'react';

import './nickname_form.scss';
import nickname from './nickname.png';

function NicknameForm({
  title = 'Choose a Nickname',
  saveButtonText = 'Start Learning!',
  onSubmit,
  errorMessage,
  setErrorMessage
}) {
  let inputRef = useRef('');

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="row center-xs">
      <div className="nickname col-md-6">
        <div className="row center-xs center-sm">
          <div className="col-xs-12">
            <img className="nickname__img " src={nickname} />
          </div>
          <div className="nickname__right-container col-xs-12">
            <h1 className="nickname__header">{title}</h1>
            <form
              onSubmit={e => {
                e.preventDefault();
                onSubmit(inputRef.current.value);
              }}
            >
              <input
                ref={inputRef}
                onKeyDown={() => {
                  if (errorMessage) {
                    setErrorMessage('');
                  }
                }}
                className="nickname__input"
                placeholder="nickname"
              />

              <div style={{ position: 'relative' }}>
                <div className="nickname__error">{errorMessage}</div>
              </div>
              <button type="submit" className="nickname__btn btn btn-primary">
                {saveButtonText}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NicknameForm;
