import axios from 'axios';
import store from 'store';

const token = store.get('token');

const client = axios.create({
  baseURL: 'https://dictize.com/api/dictize',
  timeout: 5000,
  headers: {
    Pragma: 'no-cache'
  }
});

if (token) {
  client.defaults.headers['Authorization'] = token;
}

export default client;
