import React from 'react';
import cx from 'classnames';

function LeaderboardListItem({ isUser = false, rank, nickname, points }) {
  return (
    <div
      className={cx('leaderboard__list-item', {
        'leaderboard__list-item--highlighted': isUser,
        'leaderboard__list-item--gold': !isUser && rank === 1,
        'leaderboard__list-item--silver': !isUser && rank === 2,
        'leaderboard__list-item--bronze': !isUser && rank === 3
      })}
    >
      <div className="leaderboard__list-item-rank">{rank}</div>
      <div className="leaderboard__list-item-nickname">{nickname} </div>
      <div className="leaderboard__list-item-points">{points}</div>
    </div>
  );
}

export default LeaderboardListItem;
