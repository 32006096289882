import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import { checkAnswer, goToNext } from '../../../actions';
import './check_button.scss';

function CheckButton() {
  const dispatch = useDispatch();
  const isCorrect = useSelector(({ lessons }) => lessons.isCorrect);

  return (
    <button
      onClick={() => {
        if (isCorrect) {
          dispatch(goToNext());
        } else {
          dispatch(checkAnswer());
        }
      }}
      className={cx('check-button', { 'check-button--correct': isCorrect })}
    >
      {isCorrect ? 'NEXT' : 'CHECK'}
    </button>
  );
}

export default CheckButton;
