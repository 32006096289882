import { Howl } from 'howler';

import check from '../sounds/check.mp3';
import correct from '../sounds/correct.mp3';
import finish from '../sounds/finish.mp3';
import skip from '../sounds/skip.mp3';
import revealMany from '../sounds/many.mp3';
import revealSingle from '../sounds/single.mp3';

const sounds = {
  check: { src: check, loaded: false },
  correct: { src: correct, loaded: false },
  finish: { src: finish, loaded: false },
  skip: { src: skip, loaded: false },
  revealMany: { src: revealMany, loaded: false },
  revealOne: { src: revealSingle, loaded: false }
};

function playSound(name) {
  if (!sounds.hasOwnProperty(name)) {
    throw new Error('invalid sound name ' + name);
  }

  if (!sounds[name].loaded) {
    sounds[name].howl = new Howl({ src: [sounds[name].src] });
    sounds[name].loaded = true;
  }

  sounds[name].howl.play();
}

export default playSound;
