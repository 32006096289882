import React from 'react';
import cx from 'classnames';

import './powerup.scss';

const iconType = {
  revealOne: 'bolt',
  revealMany: 'dice',
  revealFirst: 'flame'
};

function Powerup({ type, count, onClick }) {
  let powerupClass = iconType[type];

  return (
    <div
      onClick={() => {
        if (count > 0) {
          onClick(type);
        }
      }}
      className={cx('powerup', `powerup__${powerupClass}`, {
        powerup__disabled: count === 0
      })}
    >
      <div className={`powerup__icon`}>
        <div className={`powerup__icon-img powerup__${powerupClass}-img`} />
      </div>
      {count > 0 && (
        <div className="powerup__badge">
          <div className="powerup__count">{count}</div>
        </div>
      )}
    </div>
  );
}

export default Powerup;
