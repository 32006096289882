import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useHistory } from 'react-router-dom';
import PropagateLoader from 'react-spinners/PropagateLoader';
import delay from 'lodash/delay';

import CompactLeaderboard from './CompactLeaderboard';

import './results.scss';

function Results({}) {
  const [isLoading, setLoading] = useState(true);
  const [resultsVisible, setResultsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        setResultsVisible(true);
      }, 300);
    }, 800);
  }, []);

  return (
    <div className="row results center-sm">
      <CSSTransition
        in={resultsVisible}
        timeout={1000}
        classNames="fade"
        unmountOnExit
      >

        <div className="col-xs-12 col-sm-6">
          <CompactLeaderboard />
          <Link to="/app/lessons">
            <button className="play-again btn btn-primary">Play Again</button>
          </Link>
        </div>

      </CSSTransition>
      <CSSTransition
        in={isLoading}
        timeout={1000}
        classNames="spinner"
        unmountOnExit
      >
        <div className="spinner">
          <PropagateLoader color={'#187dff'} />
        </div>
      </CSSTransition>
    </div>
  );
}

export default Results;
