import { createReducer } from '@reduxjs/toolkit';
import {
  updateLessons,
  updateUser,
  updateCurrentLesson,
  enableRevealOne,
  disableRevealOne,
  updateSingleWord,
  updateAnswer,
  goToNextSentence,
  updateSetResults,
  resetLesson,
  overrideCompletedPct,
  updateMany,
  updateFirstLetters,
  updateAnswerStatus,
  setIsChecking,
  setIsPlaying,
  unlockLesson
} from './actions';

function getInitialState() {
  return {
    currentLesson: null,
    currentSentence: null,
    currentIndex: 0,
    currentAnswer: [],
    mask: [],
    fixed: [],
    isCorrect: false,
    isChecking: false,
    isPlayingSound: false,
    currentCompletedPct: 0,
    isUsingRevealOne: false,
    setResults: {},
    isSetOver: false
  };
}

const lessonsReducer = createReducer(
  {
    lessons: [],
    ...getInitialState()
  },
  {
    [updateLessons]: (state, action) => {
      state.lessons = action.payload.lessons;
    },
    [updateCurrentLesson]: (state, action) => {
      state.currentLesson = action.payload;
      state.currentSentence = action.payload.sentences[0];
      state.currentIndex = 0;
      state.currentAnswer = Array.from(
        new Array(state.currentSentence.prefilled.length),
        () => ''
      );
    },
    [enableRevealOne]: (state, action) => {
      state.isUsingRevealOne = true;
    },
    [disableRevealOne]: (state, action) => {
      state.isUsingRevealOne = false;
    },
    [updateSingleWord]: (state, action) => {
      state.currentSentence.prefilled[action.payload.index] =
        action.payload.word;
      state.currentAnswer[action.payload.index] = action.payload.word;
    },
    [updateAnswer]: (state, action) => {
      state.currentAnswer[action.payload.index] = action.payload.answer;
    },
    [goToNextSentence]: (state, action) => {
      state.isUsingRevealOne = false;
      state.currentIndex += 1;
      state.currentSentence = state.currentLesson.sentences[state.currentIndex];
      state.currentCompletedPct =
        (state.currentIndex / state.currentLesson.sentences.length) * 100;
      state.currentAnswer = Array.from(
        new Array(state.currentSentence.prefilled.length),
        () => ''
      );
      state.isCorrect = false;
      state.isChecking = false;
    },
    [updateSetResults]: (state, action) => {
      state.setResults = {
        allCorrectBonus: action.payload.all_correct_bonus,
        completionBonus: action.payload.completion_bonus,
        correctPoints: action.payload.correct_points,
        effortPoints: action.payload.effort_points,
        firstTryCorrect: action.payload.first_try_correct,
        firstTryPoints: action.payload.first_try_points,
        gainedPowerup: action.payload.gained_powerup,
        powerupsRevealFirst: action.payload.powerups_reveal_first,
        powerupsRevealMany: action.payload.powerups_reveal_many,
        powerupsRevealOne: action.payload.powerups_reveal_one,
        timeBonus: action.payload.time_bonus,
        totalExtraPoints: action.payload.total_extra_points,
        totalPoints: action.payload.total_points
      };
      state.isSetOver = true;
    },
    [overrideCompletedPct]: (state, action) => {
      state.currentCompletedPct = action.payload.pct;
    },
    [resetLesson]: state => {
      return { ...state, ...getInitialState() };
    },
    [updateMany]: (state, action) => {
      let { words, updatedIndices } = action.payload;
      for (let i = 0; i < words.length; i++) {
        let index = updatedIndices[i];
        state.currentAnswer[index] = words[i];
        state.currentSentence.prefilled[index] = words[i];
      }
    },
    [updateFirstLetters]: (state, action) => {
      let { words } = action.payload;
      state.currentAnswer = words;
    },
    [updateAnswerStatus]: (state, action) => {
      state.isCorrect = action.payload.isCorrect;
      state.isChecking = true;
      state.mask = action.payload.mask;
      state.fixed = action.payload.fixed.map(index => {
        return { index, word: action.payload.mask[index] };
      });
    },
    [setIsChecking]: (state, action) => {
      state.isChecking = action.payload.isChecking;
    },
    [setIsPlaying]: (state, action) => {
      state.isPlayingSound = action.payload.isPlayingSound;
    },
    [unlockLesson]: (state, action) => {
      state.lessons = state.lessons.map(lesson => {
        if (lesson.id === action.payload.lessonId) {
          return { ...lesson, unlocked: true };
        } else {
          return lesson;
        }
      });
    }
  }
);

const userReducer = createReducer(
  {
    isLoggedIn: false
  },
  {
    [updateUser]: (state, action) => {
      state.isLoggedIn = true;
      state.profile = action.payload.user;
      state.powerups = {
        revealFirst: action.payload.user.powerups_reveal_first,
        revealMany: action.payload.user.powerups_reveal_many,
        revealOne: action.payload.user.powerups_reveal_one
      };
    },
    [updateSingleWord]: (state, action) => {
      state.powerups.revealOne = action.payload.revealOne;
    },
    [updateMany]: (state, action) => {
      state.powerups.revealMany = action.payload.revealMany;
    },
    [updateFirstLetters]: (state, action) => {
      state.powerups.revealFirst = action.payload.revealFirst;
    }
  }
);

export { lessonsReducer, userReducer };
